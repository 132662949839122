import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import AccountService from "../../../services/account";
import ToolCalculatorService from "../../../services/tool-calculator";
import { useFilters, useHelpTexts } from "../../../../app/hooks/useSession";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";

function Tools(props) {
  const componentProps = {
    component: "views/account-scanner/components/tools",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();

  const [land, setLand] = useState(null);
  const [tool1, setTool1] = useState(null);
  const [tool2, setTool2] = useState(null);
  const [tool3, setTool3] = useState(null);
  const [chargeTime, setChargeTime] = useState("");
  const [miningPower, setMiningPower] = useState("");
  const [miningMin, setMiningMin] = useState(" ");
  const [chanceNft, setChanceNft] = useState("");
  const [nftMin, setNftMin] = useState("");
  const [powReduction, setPowReduction] = useState("");
  const [wastingPower, setWastingPower] = useState(0);
  const [nftPoints, setNftPoints] = useState(null);

  const bag = useQuery(
    [`account_bag_${props.account}`, props.account],
    async () => {
      const response = await AccountService.getTools(props.account, {
        startDate: filters.dates.startDate,
        endDate: filters.dates.endDate,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  useEffect(async () => {
    if (bag.data) {
      let landId = bag.data.land ? bag.data.land.template_id : null;
      let tool1Id = bag.data.tools[0] ? bag.data.tools[0].template_id : null;
      let tool2Id = bag.data.tools[1] ? bag.data.tools[1].template_id : null;
      let tool3Id = bag.data.tools[2] ? bag.data.tools[2].template_id : null;
      if (landId) {
        var land = await ToolCalculatorService.getTemplate(landId);
        setLand(land);
      }
      if (tool1Id) {
        var tool1 = await ToolCalculatorService.getTemplate(tool1Id);
        setTool1(tool1);
      }
      if (tool2Id) {
        var tool2 = await ToolCalculatorService.getTemplate(tool2Id);
        setTool2(tool2);
      }
      if (tool3Id) {
        var tool3 = await ToolCalculatorService.getTemplate(tool3Id);
        setTool3(tool3);
      }
    }

    // setTimeout(() => {
    //     window.$.Components.init();
    // });
  }, [bag.data]);

  useEffect(() => {
    if (tool1 || tool2 || tool3) {
      //mining power
      var landTlmMultiplier = land ? land.ease / 10 : 0;
      var tool1MiningPower = tool1 ? tool1.ease / 10 : 0;
      var too12MiningPower = tool2 ? tool2.ease / 10 : 0;
      var tool3MiningPower = tool3 ? tool3.ease / 10 : 0;
      var miningPower =
        landTlmMultiplier *
        (tool1MiningPower + too12MiningPower + tool3MiningPower);

      //charge time
      var landChargeMultiplier = land ? land.delay / 10 : 0;
      var tool1ChargeTime = tool1 ? parseInt(tool1.delay) : 0;
      var tool2ChargeTime = tool2 ? parseInt(tool2.delay) : 0;
      var tool3ChargeTime = tool3 ? parseInt(tool3.delay) : 0;
      //   var arr = [tool1ChargeTime, tool2ChargeTime, tool3ChargeTime];
      //   var max1 = Math.max.apply(null, arr);
      //   arr.splice(arr.indexOf(max1), 1);
      //   var max2 = Math.max.apply(null, arr);
      //   var chargeTime = landChargeMultiplier * (max1 + max2);
      //scenarios for cooldown:
      var cooldowns = [];
      if (tool1 && tool2 && tool3) {
        cooldowns = [tool1ChargeTime, tool2ChargeTime, tool3ChargeTime].sort();
        cooldowns = cooldowns.slice(1);
      } else if (tool1 && tool2) {
        cooldowns = [tool1ChargeTime, tool2ChargeTime].sort();
        cooldowns[0] = cooldowns[0] / 2;
      } else {
        cooldowns = [tool1ChargeTime].sort();
      }
      const chargeTime =
        cooldowns.reduce((partialSum, a) => partialSum + a, 0) *
        landChargeMultiplier;

      //chance of NFT
      var landNftMultiplier = land ? land.luck / 10 : 0;
      var tool1NftLuck = tool1 ? tool1.luck / 10 : 0;
      var tool2NftLuck = tool2 ? tool2.luck / 10 : 0;
      var tool3NftLuck = tool3 ? tool3.luck / 10 : 0;
      var chanceNft =
        landNftMultiplier * (tool1NftLuck + tool2NftLuck + tool3NftLuck);

      //pow reduction
      var tool1Difficulty = tool1 ? parseInt(tool1.difficulty) : 0;
      var tool2Difficulty = tool2 ? parseInt(tool2.difficulty) : 0;
      var tool3Difficulty = tool3 ? parseInt(tool3.difficulty) : 0;
      var powReduction = tool1Difficulty + tool2Difficulty + tool3Difficulty;

      if (miningPower > 80) {
        var wastingPower = miningPower - 80;
        miningPower = 80;
      } else var wastingPower = 0;
      setWastingPower(Math.round(wastingPower));
      var miningMin = chargeTime ? (miningPower / chargeTime) * 60 : null;
      var nftMin = chargeTime ? (chanceNft / chargeTime) * 60 : null;
      setMiningPower(
        miningPower.toFixed(2) + "%" + (wastingPower !== 0 ? "*" : "")
      );
      if (miningMin) setMiningMin(miningMin.toFixed(2) + "%/m");
      else setMiningMin("");
      if (chargeTime) {
        var minutes = Math.floor(chargeTime / 60);
        var seconds = chargeTime - minutes * 60;
        if (seconds) var text = minutes + "m " + seconds.toFixed(2) + "s";
        else var text = minutes + "m";

        setChargeTime(text);
      } else setChargeTime(chargeTime.toFixed(2) + " seconds");
      setChanceNft(chanceNft.toFixed(2) + "%");
      if (nftMin) setNftMin(nftMin.toFixed(2) + "%/m");
      else setNftMin("");
      setPowReduction(powReduction);

      const nftPoints = calculateNftPoints(land, tool1, tool2, tool3);
      setNftPoints(nftPoints);
    }
  }, [land, tool1, tool2, tool3]);

  const calculateNftPoints = (land, tool1, tool2, tool3) => {
    const land_nft_multiplier = land ? land.luck / 10 : 0;
    const tool1_nft_power =
      tool1 && tool1.rarity !== "Abundant" ? tool1.luck / 10 : 0;
    const tool2_nft_power =
      tool2 && tool2.rarity !== "Abundant" ? tool2.luck / 10 : 0;
    const tool3_nft_power =
      tool3 && tool3.rarity !== "Abundant" ? tool3.luck / 10 : 0;

    const land_cooldown_multiplier = land ? land.delay / 10 : 0;
    const tool1_cooldown =
      tool1 && tool1.rarity !== "Abundant" ? tool1.delay : 0;
    const tool2_cooldown =
      tool2 && tool2.rarity !== "Abundant" ? tool2.delay : 0;
    const tool3_cooldown =
      tool3 && tool3.rarity !== "Abundant" ? tool3.delay : 0;

    const nft_points_per_mine =
      (tool1_nft_power + tool2_nft_power + tool3_nft_power) *
      land_nft_multiplier;

    //scenarios for cooldown:
    var cooldowns = [];
    if (tool1 && tool2 && tool3) {
      cooldowns = [tool1_cooldown, tool2_cooldown, tool3_cooldown].sort();
      cooldowns = cooldowns.slice(1);
    } else if (tool1 && tool2) {
      cooldowns = [tool1_cooldown, tool2_cooldown].sort();
      cooldowns[0] = cooldowns[0] / 2;
    } else {
      cooldowns = [tool1_cooldown].sort();
    }
    const cooldown_per_mine =
      cooldowns.reduce((partialSum, a) => partialSum + a, 0) *
      land_cooldown_multiplier;
    const points_per_minute =
      (nft_points_per_mine / cooldown_per_mine) * 60 || 0;

    return {
      nft_points_per_mine: nft_points_per_mine,
      cooldown_per_mine: cooldown_per_mine,
      points_per_minute: points_per_minute,
    };
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Current mining bag`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This shows the current land the user is mining on and the current mining bag used by this account."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="row mt-2">
          <div class="col-lg-3">
            {bag.isFetching ? (
              <Skeleton />
            ) : (
              <h4 className="header-title text-center">
                {land ? land.name : ""}
              </h4>
            )}
            {land ? (
              <Link to={`/admin/nfts/${bag.data.land.asset_id}`}>
                <div className="ribbon-box">
                  <img
                    src={`https://cloudflare-ipfs.com/ipfs/${land.image}`}
                    alt="land-pic"
                    className="img-fluid mt-2 mx-auto d-block"
                    style={{ height: "300px" }}
                  />
                  <div class="card-img-overlay card-inverse p-0">
                    <div class="ribbon ribbon-primary d-inline mt-2 mx-auto">
                      <i class="fas fa-dollar-sign me-1"></i>
                      {bag.data.land.commission}
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <img
                src={`https://cloudflare-ipfs.com/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
                alt="land-pic"
                className="img-fluid mt-2 mx-auto d-block"
                style={{ height: "300px", opacity: 0.4 }}
              />
            )}
          </div>
          <div class="col-lg-3">
            {bag.isFetching ? (
              <Skeleton />
            ) : (
              <h4 className="header-title text-center">
                {tool1 ? tool1.name : ""}
              </h4>
            )}
            {tool1 ? (
              <Link to={`/admin/nfts/${bag.data.tools[0].asset_id}`}>
                <img
                  src={`https://cloudflare-ipfs.com/ipfs/${tool1.image}`}
                  alt="land-pic"
                  className="img-fluid mt-2 mt-2 mx-auto d-block"
                  style={{ height: "300px" }}
                />
              </Link>
            ) : (
              <img
                src={`https://cloudflare-ipfs.com/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
                alt="land-pic"
                className="img-fluid mt-2 mt-2 mx-auto d-block"
                style={{ height: "300px", opacity: 0.4 }}
              />
            )}
          </div>
          <div class="col-lg-3">
            {bag.isFetching ? (
              <Skeleton />
            ) : (
              <h4 className="header-title text-center">
                {tool2 ? tool2.name : ""}
              </h4>
            )}
            {tool2 ? (
              <Link to={`/admin/nfts/${bag.data.tools[1].asset_id}`}>
                <img
                  src={`https://cloudflare-ipfs.com/ipfs/${tool2.image}`}
                  alt="land-pic"
                  className="img-fluid mt-2 mt-2 mx-auto d-block"
                  style={{ height: "300px" }}
                />
              </Link>
            ) : (
              <img
                src={`https://cloudflare-ipfs.com/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
                alt="land-pic"
                className="img-fluid mt-2 mt-2 mx-auto d-block"
                style={{ height: "300px", opacity: 0.4 }}
              />
            )}
          </div>
          <div class="col-lg-3">
            {bag.isFetching ? (
              <Skeleton />
            ) : (
              <h4 className="header-title text-center">
                {tool3 ? tool3.name : ""}
              </h4>
            )}
            {tool3 ? (
              <Link to={`/admin/nfts/${bag.data.tools[2].asset_id}`}>
                <img
                  src={`https://cloudflare-ipfs.com/ipfs/${tool3.image}`}
                  alt="land-pic"
                  className="img-fluid mt-2 mt-2 mx-auto d-block"
                  style={{ height: "300px" }}
                />
              </Link>
            ) : (
              <img
                src={`https://cloudflare-ipfs.com/ipfs/QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP`}
                alt="land-pic"
                className="img-fluid mt-2 mt-2 mx-auto d-block"
                style={{ height: "300px", opacity: 0.4 }}
              />
            )}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-2">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 class="text-success mt-0">
                  {chargeTime ? <span>{chargeTime}</span> : <span>0</span>}
                </h3>
                <p class="text-muted mb-0">Charge Time</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 class="text-success mt-0">
                  {miningPower ? (
                    <>
                      <span>{miningPower}</span>
                      &nbsp;&nbsp;&nbsp;
                      <span>{miningMin}</span>
                    </>
                  ) : (
                    <span>0</span>
                  )}
                </h3>
                <p class="text-muted mb-0">Mining Power</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3">
                <div className="widget-simple text-center card">
                    <div className="card-body">
                    <h3 class="text-success mt-0">
                        {chanceNft ? (
                        <>
                            <span>{chanceNft}</span>
                            &nbsp;&nbsp;&nbsp;
                            <span>{nftMin}</span>
                        </>
                        ) : (
                        <span>0</span>
                        )}
                    </h3>
                    <p class="text-muted mb-0">NFT Luck (Stage 1)</p>
                    </div>
                </div>
                </div> */}
          <div className="col-lg-3">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 class="text-success mt-0">
                  {nftPoints ? (
                    <>
                      <span>
                        {parseFloat(nftPoints.points_per_minute).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                        /m
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <span>
                        {parseFloat(
                          nftPoints.points_per_minute * 60
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        h
                      </span>
                    </>
                  ) : (
                    <span>0</span>
                  )}
                </h3>
                <p class="text-muted mb-0">NFT points</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 class="text-success mt-0">
                  {nftPoints ? (
                    <span>{nftPoints.nft_points_per_mine}</span>
                  ) : (
                    <span>0</span>
                  )}
                </h3>
                <p class="text-muted mb-0">NFT Power</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="widget-simple text-center card">
              <div className="card-body">
                <h3 class="text-success mt-0">
                  {powReduction ? <span>{powReduction}</span> : <span>0</span>}
                </h3>
                <p class="text-muted mb-0">POW Reduction</p>
              </div>
            </div>
          </div>
        </div>

        {wastingPower != 0 ? (
          <div className="row">
            <div className="col-lg-12">
              <div className="widget-simple text-center card">
                <div className="card-body">
                  <p class="text-muted mb-0">
                    The maximum mining power is 80%, this build is not using
                    approx {wastingPower + "%"} mining power
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {bag.isError ? <Error500 /> : null}

        <div className="row">
          <div className="col-lg-12">
            <Link
              to={
                "/admin/tool-calculator?" +
                (land ? "land=" + land.template_id : "") +
                (tool1 ? "&tool1=" + tool1.template_id : "") +
                (tool2 ? "&tool2=" + tool2.template_id : "") +
                (tool3 ? "&tool3=" + tool3.template_id : "")
              }
            >
              <button
                type="buttom"
                className="btn btn-success mb-2 mb-sm-0 mx-auto d-block"
              >
                Analyse mining setup
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tools;
