import { useQuery } from "react-query";
import AccountService from "../../../services/account";
import CardMenu from "admin/components/card-menu";
import { Link } from "react-router-dom";

export const UnclaimedNfts = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/unclaimed-nfts",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };

  const templates = useQuery(
    [`unclaimed_nfts_${props.account}`, props.account],
    () => {
      return AccountService.getUnclaimedNfts(props.account);
    }
  );

  return (
    <>
    {(templates.data && templates.data.length > 0) ? (
    <div className="row">
        <div class="col-lg-12">
            <div className="card">
            <div className="card-body">
                <CardMenu {...componentProps} {...props} />
                <h4 className="header-title">Unclaimed NFTs of {props.account}</h4>   
                <div class="responsive-table-plugin">
                    <div class="table-rep-plugin">
                        <div class="table-responsive" data-pattern="priority-columns">  
                            <table className="table mb-0">
                            <thead>
                                <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Template ID</th>
                                <th>Shine</th>
                                <th>Rarity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {templates.data &&
                                templates.data.map((template, index) => {
                                    return (
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/admin/template/${template.template_id}`}>
                                                <img
                                                src={`https://cloudflare-ipfs.com/ipfs/${template.image}`}
                                                alt="nfts-pic"
                                                style={{width:"50px"}}
                                                />
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/admin/template/${template.template_id}`}>
                                            <h5 className="mt-0 mb-1 text-truncate">{template.name}</h5>
                                            </Link>
                                        </td>
                                        <td>
                                            {template.template_id}
                                        </td>
                                        <td>
                                            {template.shine}
                                        </td>
                                        <td>
                                            {template.rarity}
                                        </td>
                                    </tr>
                                    );
                                })}
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    ) : null }
    </>
  );
};

export default UnclaimedNfts;
