import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import NftsService from "../../services/nfts";
import Skeleton from "react-loading-skeleton";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import NftHistory from "./components/history";
import TlmEarningsPerDay from "./components/tlm-earnings-per-day";
import LandStats from "./components/land-stats";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import IpfsImage from "admin/components/ipfs-image";
import { useEffect, useState } from "react";
import config from "app/config";
import TopMiners from "../mining-explorer/components/top-miners";
import FirstFiveUsersWith200Mines from "../mining-explorer/components/first-five-users-with-200-mines";

export const AdminNftDetail = () => {
  const history = useHistory();
  let { assetId } = useParams();

  const [isTainted, setIsTainted] = useState(false);
  const [message, setMessage] = useState("");

  const query = JSON.stringify({
    bool: { must: [{ term: { act_data_land_id: assetId } }] },
  });

  const asset = useQuery([`nfts_details_${assetId}`, assetId], () => {
    return NftsService.getNft(assetId);
  });
  //asset.status = "loading";
  console.log(asset);

  // if (asset.status === "loading") {
  //   return <></>;
  // }

  useEffect(async () => {
    const response = await NftsService.checkTainted(assetId);
    if (response.is_tainted) {
      setIsTainted(true);
      setMessage(response.message);
    }
  }, [asset.data]);

  const markTainted = async (e) => {
    e.preventDefault();
    window.$("#tainted-modal").modal("hide");

    try {
      const response = await NftsService.markTainted(assetId, {
        message: message,
      });

      if (response.status === "error") {
        throw new Error(response.message);
      }

      setIsTainted(true);

      window.$.toast({
        heading: "Success",
        text: `Asset marked as tainted`,
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Mark asset as tainted failed <br/> " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const removeTainted = async () => {
    try {
      const response = await NftsService.removeTainted(assetId);

      if (response.status === "error") {
        throw new Error(response.message);
      }

      setIsTainted(false);

      window.$.toast({
        heading: "Success",
        text: `Removed tainted marking`,
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Removing tainted marking failed <br/> " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <BreadcrumbsItem to="/admin/dashboards">Dashboards</BreadcrumbsItem>
      <BreadcrumbsItem to="/admin/dashboard-nfts">NFTs</BreadcrumbsItem>
      <BreadcrumbsItem to={`/admin/nfts/${assetId}`}>Detail</BreadcrumbsItem>

      {history.action !== "POP" ? (
        <div className="row">
          <div class="col-lg-12">
            <button
              type="button"
              class="btn btn-link waves-effect waves-light"
              onClick={() => {
                if (history.action !== "POP") history.goBack();
              }}
              disabled={history.action === "POP"}
            >
              <i class="fas fa-chevron-left me-1"></i> Back
            </button>
          </div>
        </div>
      ) : null}

      {asset.status !== "loading" && (!asset.data || !asset.data.asset_id) ? (
        <div className="row justify-content-center my-5">
          <div className="col-lg-6 col-xl-4 mb-4">
            <div className="text-center">
              <img
                src="/assets/images/error.svg"
                alt="errorimage"
                width="200"
              />
            </div>
            <div className="text-center">
              <h3 className="mt-2 mb-2">Asset not found </h3>
            </div>
          </div>
        </div>
      ) : null}

      {asset.status === "loading" || (asset.data && asset.data.asset_id) ? (
        <>
          {asset.data && asset.data.schema_name.split(".")[0] == "land" ? (
            <div className="row">
              <div class="col-lg-12">
                <LandStats land_id={assetId} />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div class="col-lg-12">
              <h3>
                Asset: #
                {asset.status === "loading" ? (
                  <Skeleton width={150} />
                ) : (
                  <>
                    {asset.data.asset_id} ({asset.data.name})
                  </>
                )}
              </h3>
            </div>
          </div>
          <div className="row mb-3">
            <div class="col-lg-4">
              <div class="card  h-100">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div
                        id="product-carousel"
                        className="carousel slide product-detail-carousel"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {asset.data && asset.data.data && asset.data.data.img ? (
                            <div className="carousel-item active">
                              <div className="">
                                {asset.status === "loading" ? (
                                  <Skeleton height={300} />
                                ) : (
                                  <IpfsImage
                                    src={`https://cloudflare-ipfs.com/ipfs/${asset.data.data.img}`}
                                    alt="asset-img"
                                    className="img-fluid mx-auto d-block"
                                    style={{ height: "300px" }}
                                  />
                                )}
                              </div>
                            </div>
                          ) : null}

                          {asset.data && asset.data.data && asset.data.data.backimg ? (
                            <div className="carousel-item">
                              <div className="">
                                {asset.status === "loading" ? (
                                  <Skeleton height={300} />
                                ) : (
                                  <IpfsImage
                                    src={`https://cloudflare-ipfs.com/ipfs/${asset.data.data.backimg}`}
                                    alt="asset-img"
                                    className="img-fluid mx-auto d-block"
                                    style={{ height: "300px" }}
                                  />
                                )}
                              </div>
                            </div>
                          ) : null}

                          {asset.data && asset.data.data && asset.data.data.video ? (
                            <div classname="carousel-item active">
                              <video controls style={{ maxWidth: "100%" }}>
                                <source
                                  src={`https://cloudflare-ipfs.com/ipfs/${asset.data.data.video}`}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          ) : null}
                        </div>
                        <ol className="carousel-indicators product-carousel-indicators mt-2">
                          {asset.data && asset.data.data && asset.data.data.img ? (
                            <li
                              data-bs-target="#product-carousel"
                              data-bs-slide-to="0"
                              className="active"
                            >
                              {asset.status === "loading" ? (
                                <Skeleton height={100} />
                              ) : (
                                <IpfsImage
                                  src={`https://cloudflare-ipfs.com/ipfs/${asset.data.data.img}`}
                                  alt="asset-img"
                                  className="img-fluid product-nav-img"
                                />
                              )}
                            </li>
                          ) : null}
                          {asset.data && asset.data.data && asset.data.data.backimg ? (
                            <li
                              data-bs-target="#product-carousel"
                              data-bs-slide-to="1"
                            >
                              {asset.status === "loading" ? (
                                <Skeleton height={100} />
                              ) : (
                                <IpfsImage
                                  src={`https://cloudflare-ipfs.com/ipfs/${asset.data.data.backimg}`}
                                  alt="asset-img"
                                  className="img-fluid product-nav-img"
                                />
                              )}
                            </li>
                          ) : null}

                          {/* {asset.data && asset.data.data && asset.data.data.video ? (
                            <li
                              data-bs-target="#product-carousel"
                              data-bs-slide-to="0"
                              className="active"
                            >
                              {asset.status === "loading" ? (
                                <Skeleton height={100} />
                              ) : (
                                <IpfsImage
                                  src={`https://cloudflare-ipfs.com/ipfs/${asset.data.data.video}`}
                                  alt="asset-img"
                                  className="img-fluid product-nav-img"
                                />
                              )}
                            </li>
                          ) : null} */}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="card  h-100">
                <div className="card-body">
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Asset Name</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">
                            {asset.data.name} &nbsp;
                            <a
                              href={`${config.atomichub.url}/explorer/asset/${assetId}`}
                              target="_blank"
                            >
                              <i class="fas fa-external-link-alt"></i>
                            </a>
                          </h5>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Collection Name</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">{asset.data.collection_name}</h5>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">ID</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">#{asset.data.asset_id}</h5>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Schema Name</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">{asset.data.schema_name}</h5>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Owner</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">
                            {asset.data.burned_by_account ? (
                              <>
                                Burned by&nbsp;
                                <Link
                                  to={`/admin/account-scanner/${asset.data.burned_by_account}`}
                                >
                                  {asset.data.burned_by_account}
                                </Link>
                              </>
                            ) : (
                              <Link
                                to={`/admin/account-scanner/${asset.data.owner}`}
                              >
                                {asset.data.owner}
                              </Link>
                            )}
                          </h5>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Template ID</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">#{asset.data.template_id}</h5>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Mint number</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">
                            {asset.data.template_mint ? (
                              <>
                                <span className="text-primary">
                                  {asset.data.template_mint}
                                </span>
                                &nbsp;of {asset.data.template.issued_supply}
                              </>
                            ) : (
                              "None"
                            )}
                          </h5>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Properties</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <>
                            {asset.data.transferable ? (
                              <h5 class="mt-0">Transferable</h5>
                            ) : null}
                            {asset.data.burnable ? (
                              <h5 class="mt-0">Burnable</h5>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Backed Tokens</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">
                            {asset.data.amount
                              ? asset.data.amount / 100000000 + " WAX"
                              : "None"}
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Minted on</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5>
                            {moment
                              .tz(parseInt(asset.data.minted_at_time), "UTC")
                              .format("Do MMMM YYYY hh:mm A")}
                            &nbsp; (
                            {moment
                              .tz(parseInt(asset.data.minted_at_time), "UTC")
                              .fromNow()}
                            )
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Last transfer</p>
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5>
                            {moment
                              .tz(
                                parseInt(asset.data.transferred_at_time),
                                "UTC"
                              )
                              .format("Do MMMM YYYY hh:mm A")}
                            &nbsp; (
                            {moment
                              .tz(
                                parseInt(asset.data.transferred_at_time),
                                "UTC"
                              )
                              .fromNow()}
                            )
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        {asset.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <>
                            {!isTainted ? (
                              <button
                                type="button"
                                className="btn btn-danger mt-2 mb-sm-0"
                                data-bs-toggle="modal"
                                data-bs-target="#tainted-modal"
                              >
                                Mark as tainted asset
                              </button>
                            ) : (
                              <>
                                <p class="mb-1">
                                  This asset is marked as tainted
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-danger ms-2 mb-sm-0"
                                    onClick={removeTainted}
                                  >
                                    X
                                  </button>
                                </p>
                                <h5 className="text-warning">{message}</h5>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div class="col-lg-6">
              <div class="card h-100">
                <div className="card-body">
                  <h4 class="header-title mb-4">Immutable Attributes</h4>
                  <table>
                    {asset.isLoading ? (
                      <Skeleton />
                    ) : (
                      <>
                        {Object.keys(asset.data.immutable_data || {}).map(
                          (attribute, index) => (
                            <tr key={index}>
                              <td>{attribute}</td>
                              <td>{asset.data.immutable_data[attribute]}</td>
                            </tr>
                          )
                        )}
                        {Object.keys(asset.data.immutable_data || {}).length ===
                        0 ? (
                          <p>No immutable attributes</p>
                        ) : null}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-6 ">
              <div class="card h-100">
                <div className="card-body">
                  <h4 class="header-title mb-4">Mutable Attributes</h4>
                  <table>
                    {asset.isLoading ? (
                      <Skeleton />
                    ) : (
                      <>
                        {Object.keys(asset.data.mutable_data || {}).map(
                          (attribute, index) => (
                            <tr key={index}>
                              <td>{attribute}</td>
                              <td>{asset.data.mutable_data[attribute]}</td>
                            </tr>
                          )
                        )}
                        {Object.keys(asset.data.mutable_data || {}).length ===
                        0 ? (
                          <p>No mutable attributes</p>
                        ) : null}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-12">
              <NftHistory
                type={asset.data ? asset.data.schema_name.split(".")[0] : ""}
              />
            </div>
          </div>
          {asset.data && asset.data.schema_name.split(".")[0] == "land" ? (
            <>
              <div className="row">
                <div class="col-lg-12">
                  <TlmEarningsPerDay land_id={assetId} />
                </div>
              </div>
              <div className="row">
                <div class="col-lg-12">
                  <TopMiners query={query} />
                </div>
              </div>
              <div className="row">
                <div class="col-lg-12">
                  <FirstFiveUsersWith200Mines query={query} />
                </div>
              </div>
            </>
          ) : null}

          <div
            id="tainted-modal"
            className="modal fade"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center mt-2 mb-4">
                    <Link to="/admin" className="text-success">
                      <span>
                        <img
                          src="../../assets/images/logo-light.png"
                          alt=""
                          height="24"
                        />
                      </span>
                    </Link>
                  </div>

                  <form className="px-3" action="#">
                    <div className="mb-3">
                      <label for="message" className="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        onChange={(e) => setMessage(e.target.value)}
                        rows="5"
                      >
                        {message}
                      </textarea>
                    </div>

                    <div className="mb-3 text-center">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={markTainted}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AdminNftDetail;
