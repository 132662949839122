import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFilters, useHelpTexts } from "../../../app/hooks/useSession";
import ApiService from "../../../app/services/api";
import { useParams, useHistory } from "react-router-dom";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { Link } from "react-router-dom";
import Error500 from "admin/components/errors/error-500";
import IpfsImage from "admin/components/ipfs-image";
import moment from "moment-timezone";
import { copyToClipboard } from "app/utils/datatables";

export const TransferFlow = (match) => {
  const componentProps = {
    component: "components/graph/TransferFlow",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 9 },
  };
  const helpTexts = useHelpTexts();
  const filters = useFilters();
  const history = useHistory();
  const tableRef = useRef();
  const dataTableRef = useRef();

  const [types, setTypes] = useState({
    tlm: true,
    wax: true,
    nft: true,
  });

  const options = {
    nodes: {
      shape: "dot",
      font: {
        color: "#acbfd2",
        face: "Roboto,sans-serif",
      },
    },
    edges: {
      scaling: {
        customScalingFunction: function (min, max, total, value) {
          return value / max;
        },
      },
    },
    interaction: {
      hover: true,
    },
    // manipulation: {
    //     enabled: true
    // },
    physics: {
      maxVelocity: 50,
      solver: "forceAtlas2Based",
      timestep: 0.35,
      stabilization: false,
    },
    // autoResize: true,
    // height: "100vh"
  };
  var nodes = new DataSet([]);
  var edges = new DataSet([]);
  const visJsRef = useRef(null);
  const search = useRef(null);
  var network = null;
  const [isData, setIsData] = useState(true);
  const [limit, setlimit] = useState(false);
  const [viewAll, setViewAll] = useState(0);
  const params = useParams();
  const account = params.accounts ? params.accounts : "";
  const [accounts, setAccounts] = useState(account);
  const [autocompleteItems, setAutocompleteItems] = useState([]);
  const [showAutocompleteItems, setShowAutocompleteItems] = useState(false);
  const [assetsTransfers, setAssetsTransfers] = useState([]);
  const [selectedNodeData, setSelectedNodeData] = useState({
    node: "",
    data: [],
  });

  const forensics = useQuery(
    [`forensics`, filters, accounts, viewAll, types],
    async () => {
      if (accounts !== "") {
        const response = await ApiService.get(`forensics`, {
          startDate: filters.dates.startDate,
          endDate: filters.dates.endDate,
          accounts: accounts,
          viewAll: viewAll,
          filters: JSON.stringify(types),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.edges.length == 0 && account != "") setIsData(false);
        else {
          setIsData(true);
          if (data.limit) setlimit(true);
          else setlimit(false);
          nodes.update(data.nodes);
          edges.update(data.edges);
          setAssetsTransfers(data.asset_transfers);
        }
        network =
          visJsRef.current && new Network(visJsRef.current, data, options);
        if (network != null) {
          network.on("click", async function (event) {
            var account = event.nodes[0];
            if (account && account !== "Mining" && account != "Mining Comm.") {
              var data = accounts + "-" + account;
              setAccounts(data);
              setViewAll(0);
              history.push({
                pathname: "/admin/forensics/" + data,
              });

              let response = await ApiService.get("forensics", {
                startDate: filters.dates.startDate,
                endDate: filters.dates.endDate,
                accounts: account,
                viewAll: viewAll,
                filters: JSON.stringify(types),
              });
              if (!response.ok) {
                throw Error("Unable to process");
              }
              data = await response.json();
              var edges = data.edges.sort((a, b) => b.value - a.value);
              setSelectedNodeData({ node: account, data: edges });
            }
          });
          network.on("afterDrawing", function (ctx) {
            var dataURL = ctx.canvas.toDataURL("image/jpeg", 1.0);
            window.$("#download").attr("href", dataURL);
            window.$("#download").attr("download", accounts + ".jpg");
          });
        }
      } else return null;
    },
    {
      refetchOnWindowFocus: false,
      // notifyOnChangeProps: [isData]
    }
  );

  useEffect(() => {
    search.current.value = account;
  }, [account]);

  if (dataTableRef.current) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window.$(tableRef.current).DataTable({
          language: {
            paginate: {
              previous: "<i class='mdi mdi-chevron-left'>",
              next: "<i class='mdi mdi-chevron-right'>",
            },
          },
          lengthMenu: [10, 100, 500],
          dom: "Blfrtip",
          columnDefs: [{ targets: "no-sort", orderable: false }],
          buttons: [
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("From")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
            {
              extend: "copy",
              title: null,
              header: false,
              exportOptions: {
                columns: ':contains("To")',
                rows: ":visible",
                customizeData: function (dataTable) {
                  dataTable.body = [
                    ...new Map(
                      dataTable.body.map((item) => [item[0], item])
                    ).values(),
                  ];
                },
              },
            },
          ],
        });
      window.$(".buttons-copy").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }
  }, [assetsTransfers]);

  const searchAccount = (event) => {
    event.preventDefault();
    var value = search.current.value;
    setAccounts(value);
    setIsData(true);
    setlimit(false);
    setViewAll(0);
    history.push({
      pathname: "/admin/forensics/" + value,
    });
  };

  const setFilters = (type) => {
    types[type] = types[type] ? false : true;
    setTypes({ ...types });
  };

  const exportCSV = async () => {
    const response = await ApiService.get("forensics/csv", {
      startDate: filters.dates.startDate,
      endDate: filters.dates.endDate,
      accounts: accounts,
      filters: JSON.stringify(types),
    });
    if (!response.ok) {
      throw Error("Unable to process");
    }
    const csv = await response.blob();

    let url = window.URL.createObjectURL(csv);
    let a = document.createElement("a");
    a.href = url;
    a.download = accounts + ".csv";
    a.click();
  };

  const autocompleteAccount = async (el) => {
    const values = el.target.value.split("-");
    const value = values[values.length - 1];
    const lower_bound = value;
    const upper_bound = lower_bound.padEnd(12, "z");
    const data = await fetch(
      "https://chain.wax.io/v1/chain/get_table_by_scope",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: "eosio",
          limit: 5,
          lower_bound: lower_bound,
          table: "userres",
          upper_bound: upper_bound,
        }),
      }
    ).then((response) => response.json());

    if (data && data.rows) {
      setAutocompleteItems(data.rows);
    }
  };

  const selectItem = (item) => {
    // setShowAutocompleteItems(false);
    if (search.current.value.includes("-"))
      search.current.value =
        search.current.value.substring(
          0,
          search.current.value.lastIndexOf("-") + 1
        ) + item.scope;
    else search.current.value = item.scope;
  };

  const handleOnFocus = () => {
    setShowAutocompleteItems(true);
  };

  const handleOnBlur = () => {
    setTimeout(function () {
      setShowAutocompleteItems(false);
    }, 1000);
  };

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#nftsTransfers_wrapper .btn-group .buttons-copy`)[0].click();
    copyToClipboard("nftsTransfers", 0);
  };

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#nftsTransfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("nftsTransfers", 1);
  };

  const copyAccounts = () => {
    window.navigator.clipboard.writeText(accounts.replace("-", "\n"));
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="dropdown float-end">
          <a
            href="#"
            className="dropdown-toggle arrow-none card-drop"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="mdi mdi-dots-horizontal"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-end">
            <a
              href="javascript:void(0);"
              id="download"
              className="dropdown-item"
            >
              Export as image
            </a>
            <a
              href="javascript:void(0);"
              onClick={exportCSV}
              className="dropdown-item"
            >
              Export as CSV
            </a>
          </div>
        </div>
        <h4 className="header-title">
          {helpTexts.find(
            (helpText) => helpText.key === componentProps.component
          )?.title || `Forensics`}
          <button
            type="button"
            className="btn btn-outline-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-ht-key={componentProps.component}
            title={
              helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.value ||
              "This is a tool that helps identify patterns of how TLM moved through an account. It shows how TLM flowed into an account and out of it. The thickness of the lines are proportional to the amount of TLM flowing through it."
            }
            style={{ boxShadow: "none" }}
          >
            <i class="fas fa-info-circle"></i>
          </button>
        </h4>
        <div className="dropdown">
          <form className="d-flex align-items-center" onSubmit={searchAccount}>
            <input
              type="text"
              name="search"
              className="form-control dropdown-toggle"
              id="autocomplateAccountName"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              placeholder="Enter an account name. For multiple use - to separate. Eg: one.wam-two.wam-three.wam"
              aria-label="Search"
              autoComplete={"off"}
              ref={search}
              onChange={autocompleteAccount}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              ref={search}
            />
            <button type="submit" className="btn btn-success mb-2 mb-sm-0">
              Search
            </button>
            <div
              className={`dropdown-menu ${
                autocompleteItems.length > 0 && showAutocompleteItems
                  ? "show"
                  : "d-none"
              }`}
              aria-labelledby="autocomplateAccountName"
            >
              {autocompleteItems.map((autocompleteItem, index) => (
                <Link
                  className="dropdown-item"
                  key={index}
                  onClick={() => selectItem(autocompleteItem)}
                >
                  {autocompleteItem.scope}
                </Link>
              ))}
            </div>
          </form>
        </div>
        <div class="mt-3">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="tlm"
              onChange={() => setFilters("tlm")}
              defaultChecked={types.tlm ? true : false}
            />
            <label class="form-check-label" for="tlm">
              TLM
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="wax"
              onChange={() => setFilters("wax")}
              defaultChecked={types.wax ? true : false}
            />
            <label class="form-check-label" for="wax">
              WAX
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="nft"
              onChange={() => setFilters("nft")}
              defaultChecked={types.nft ? true : false}
            />
            <label class="form-check-label" for="nft">
              NFT
            </label>
          </div>
          {accounts !== "" && isData == true ? (
            <button
              type="button"
              className="btn btn-xs float-end btn-success"
              onClick={copyAccounts}
            >
              Copy nodes to clipboard
            </button>
          ) : null}
        </div>
        {forensics.status === "loading" && isData == true ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : null}
        {forensics.isError ? <Error500 /> : null}
        {limit ? (
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            Your search has been limited to 200 results.
            <button
              type="button"
              className="btn btn-link waves-effect waves-light"
              onClick={() => {
                setViewAll(1);
              }}
            >
              Show All
            </button>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        ) : null}
        <div className="mt-3 text-center">
          <div dir="ltr">
            {isData == true ? (
              <div ref={visJsRef} style={{ height: "100vh" }} />
            ) : (
              <p>
                This account does not exist or does not have any transfers
                within dates specified
              </p>
            )}
            {/* <a href="" style={{display: "none"}} >download</a> */}
          </div>
        </div>

        {selectedNodeData.node != "" ? (
          <>
            <h4 className="header-title">
              Selected node: {selectedNodeData.node}
            </h4>
            <table className="table table-centered dt-responsive w-100 table-nowrap">
              <thead className="table-light"></thead>
              <tbody>
                {selectedNodeData.data &&
                  selectedNodeData.data.map((edge, index) => {
                    let amount = edge.value;
                    let wallet =
                      edge.from == selectedNodeData.node ? edge.to : edge.from;
                    let action =
                      edge.from == selectedNodeData.node ? "sent" : "receive";
                    let type =
                      edge.color == "blue"
                        ? "TLM"
                        : edge.color == "red"
                        ? "WAX"
                        : "NFT";
                    return (
                      <tr key={index}>
                        <td>
                          <h4 className="header-title">
                            {amount + " " + type}
                          </h4>
                          {action == "sent" ? (
                            <p>
                              <i class="fas fa-arrow-right me-2 text-danger"></i>
                              {"Sent to " + wallet}
                            </p>
                          ) : (
                            <p>
                              <i class="fas fa-arrow-left me-2 text-success"></i>
                              {"Received from " + wallet}
                            </p>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        ) : null}
        {types.nft ? (
          <table
            className="table table-centered dt-responsive w-100 table-nowrap"
            ref={tableRef}
            id="nftsTransfers"
          >
            <thead className="table-light">
              <tr>
                {/* <th className="text-center">Asset</th> */}
                <th>Date</th>
                <th className="no-sort">
                  From &nbsp;
                  <Link to="" onClick={copyFromAddressToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy from address to clipboard"
                    ></i>
                  </Link>
                </th>
                <th className="no-sort">
                  To &nbsp;
                  <Link to="" onClick={copyToAddressToClipboard}>
                    <i
                      className="fas fa-copy"
                      title="Copy to address to clipboard"
                    ></i>
                  </Link>
                </th>
                <th>Memo</th>
                <th>NFts</th>
              </tr>
            </thead>
            <tbody>
              {assetsTransfers &&
                assetsTransfers.map((transfer, index) => (
                  <tr key={index}>
                    {/* <td className="text-center">
                      <Link to={`/admin/nfts/${transfer.asset_id}`}>
                        <IpfsImage
                          src={`https://cloudflare-ipfs.com/ipfs/${
                            transfer.asset ? transfer.asset.data.img : ""
                          }`}
                          alt="nfts-pic"
                          style={{ width: "50px" }}
                        />
                        <p>{transfer.asset ? transfer.asset.name : "N/A"}</p>
                      </Link>
                    </td> */}
                    <td title={moment(transfer.timestamp).fromNow()}>
                      {moment
                        .tz(transfer.timestamp, "UTC")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>{transfer.transfer_from}</td>
                    <td>{transfer.transfer_to}</td>
                    <td>{transfer.transfer_memo}</td>
                    <td>
                      {transfer.transfer_asset_ids.map((asset_id) => {
                        return (
                          <Link to={`admin/nfts/${asset_id}`} target="_blank">
                            {asset_id}
                          </Link>
                        );
                      })}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default TransferFlow;
