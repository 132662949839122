import { useEffect, useState } from "react";
import SocketService from "../../../../app/services/socket";
import CardMenu from "admin/components/card-menu";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import IpfsImage from "admin/components/ipfs-image";
import { Link } from "react-router-dom";
import { useHelpTexts } from "app/hooks/useSession";

export const AdminRealtimeAtomicassetsNftTransfers = (props) => {
  const componentProps = {
    component: "components/realtime/atomicassets/nft-transfers",
    options: {},
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
  };
  const helpTexts = useHelpTexts();
  const [assetsTransfers, setAssetsTransfers] = useState([]);
  const [hideNfts, setHideNfts] = useState(false);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    var socket = SocketService.getSocket("/atomicassets");

    if (socket.connected) {
      socket.emit("join", "nftTransfers");
    } else {
      socket.on("connect", function () {
        socket.emit("join", "nftTransfers");
      });
    }

    socket.on("nftTransfers", function (data) {
      setAssetsTransfers(data);
    });

    return () => {
      socket.off("nftTransfers");
      socket.emit("leave", "nftTransfers");
    };
  }, []);

  useEffect(() => {
    if (hideNfts) setTemplates(["19552", "19648", "19649"]);
    else setTemplates([]);
  }, [hideNfts]);

  return (
    <>
      <div className="card bg-transparent">
        <div className="card-body">
          <CardMenu {...componentProps} {...props} />
          <div className="float-sm-end">
            <form className="d-flex align-items-center flex-wrap">
              <div className="form-check me-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="systemaccounts-check"
                  defaultChecked={hideNfts}
                  onChange={(el) => setHideNfts(!hideNfts)}
                />
                <label
                  className="form-check-label"
                  htmlFor="systemaccounts-check"
                >
                  Hide initial NFTs
                </label>
              </div>
            </form>
          </div>
          <h4 className="header-title">
            {helpTexts.find(
              (helpText) => helpText.key === componentProps.component
            )?.title || `Latest NFT Transfers (last 15 mins)`}
            <button
              type="button"
              class="btn btn-outline-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-ht-key={componentProps.component}
              title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.value ||
                "This data is for the last 15 minutes of data to indicate how healthy the system is. If the data is missing it could mean the real time indexing is at fault rather than actual problems on the blockchain."
              }
              style={{ boxShadow: "none" }}
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </h4>
          <div class="responsive-table-plugin">
            <div class="table-rep-plugin">
              <div class="table-responsive" data-pattern="priority-columns">
                <table className="table table-centered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="text-center">Asset</th>
                      <th>Date</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Memo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!assetsTransfers ? (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {assetsTransfers &&
                          assetsTransfers.map((transfer, index) => (
                            <>
                              {!templates.includes(
                                transfer.asset ? transfer.asset.template_id : 0
                              ) ? (
                                <tr key={index}>
                                  <td className="text-center">
                                    <Link
                                      to={`/admin/nfts/${transfer.asset_id}`}
                                    >
                                      <IpfsImage
                                        src={`https://cloudflare-ipfs.com/ipfs/${
                                          transfer.asset
                                            ? transfer.asset.data.img
                                            : ""
                                        }`}
                                        alt="nfts-pic"
                                        style={{ width: "50px" }}
                                      />
                                      <p>
                                        {transfer.asset
                                          ? transfer.asset.name
                                          : "N/A"}
                                      </p>
                                    </Link>
                                  </td>
                                  <td
                                    title={moment(
                                      parseInt(transfer.created_at_time)
                                    ).fromNow()}
                                  >
                                    {moment
                                      .tz(
                                        parseInt(transfer.created_at_time),
                                        "UTC"
                                      )
                                      .format("YYYY-MM-DD HH:mm:ss")}
                                  </td>
                                  <td>{transfer.sender}</td>
                                  <td>{transfer.recipient}</td>
                                  <td>{transfer.memo}</td>
                                </tr>
                              ) : null}
                            </>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRealtimeAtomicassetsNftTransfers;
