import { useQuery } from "react-query";
import { useState } from "react";
import AccountService from "../../../services/account";
import Skeleton from "react-loading-skeleton";
import Error500 from "admin/components/errors/error-500";
import config from "app/config";
import moment from "moment-timezone";
import ApiService from "app/services/api";
import { useUser } from "app/hooks/useSession";

export const Profile = (props) => {
  const [reloadProfile, setReloadProfile] = useState(1);
  const [reason, setReason] = useState("");
  const sessionUser = useUser();

  const profile = useQuery(
    [`account_profile_${props.account}`, props.account, reloadProfile],
    async () => {
      const response = await AccountService.getProfile(props.account, {
        reload: reloadProfile,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  );

  const blockAccount = async () => {
    try {
      if (reason === "") {
        throw Error("Blocking reason is required");
      }
      const response = await ApiService.post("bots/request-block", {
        account: props.account,
        reason: reason,
      });
      if (!response) {
        throw Error("Unable to process");
      }

      window.$.toast({
        heading: "Success",
        text: "blocking request sent successfully.",
        position: "top-right",
        icon: "success",
      });

      setReloadProfile(reloadProfile + 1);
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const unblockAccount = async () => {
    try {
      if (reason == "") {
        throw Error("Unblocking reason is required");
      }
      const response = await ApiService.post("bots/request-unblock", {
        account: props.account,
        reason: reason,
      });
      if (!response) {
        throw Error("Unable to process");
      }

      window.$.toast({
        heading: "Success",
        text: "unblocking request sent successfully.",
        position: "top-right",
        icon: "success",
      });

      setReloadProfile(reloadProfile + 1);
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  const cancelProcess = async () => {
    try {
      const response = await ApiService.post("bots/cancel-process", {
        account: props.account,
      });
      if (!response) {
        throw Error("Unable to process");
      }

      window.$.toast({
        heading: "Success",
        text: "process cancelled successfully.",
        position: "top-right",
        icon: "success",
      });

      setReloadProfile(reloadProfile + 1);
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="card mt-2">
        <div className="card-body">
          {profile.status === "loading" ? (
            <>
              <Skeleton height={300} />
              <h4 className="header-title text-center">
                <Skeleton width={50} />
              </h4>
              <p className="text-center">
                <Skeleton width={100} />
              </p>
            </>
          ) : (
            <>
              {profile.isError ? (
                <Error500 />
              ) : (
                <>
                  <img
                    src={`https://cloudflare-ipfs.com/ipfs/${profile.data.image}`}
                    alt="profile-img"
                    className="img-fluid mx-auto d-block"
                    style={{ height: "250px" }}
                  />
                  <h4 className="header-title text-center mt-2">
                    {profile.data.name}&nbsp;
                    <a
                      href={`${config.explorer.url}/account/${props.account}`}
                      target="_blank"
                    >
                      <i class="fas fa-external-link-alt"></i>
                    </a>
                  </h4>
                  {sessionUser.level == "level2" ||
                  sessionUser.role == "super_admin" ? (
                    <div className="text-center mt-2">
                      <div class="btn-group">
                        <button
                          type="buttom"
                          className={`btn btn-xs btn-${
                            profile.data.processing
                              ? "primary"
                              : profile.data.bot
                              ? "danger"
                              : "success"
                          } dropdown-toggle`}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {profile.data.processing
                            ? "Processing"
                            : profile.data.bot
                            ? "Flagged"
                            : "Unflagged"}
                          <i class="mdi mdi-chevron-down"></i>
                        </button>

                        <div class="dropdown-menu">
                          {sessionUser.level == "level2" ||
                          sessionUser.role == "super_admin" ? (
                            <>
                              {!profile.data.processing ? (
                                <>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#block-modal"
                                  >
                                    Block
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#unblock-modal"
                                  >
                                    Unblock
                                  </a>
                                </>
                              ) : (
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancel-modal"
                                >
                                  Cancel
                                </a>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      {profile.data && profile.data.processing ? (
                        <button
                          type="button"
                          class="btn btn-xs btn-outline-none"
                          style={{ boxShadow: "none" }}
                          data-bs-toggle="modal"
                          data-bs-target="#processing-info-modal"
                        >
                          <i class="fas fa-info-circle"></i>
                        </button>
                      ) : null}
                      {profile.data &&
                      profile.data.bot &&
                      !profile.data.processing ? (
                        <button
                          type="button"
                          class="btn btn-xs btn-outline-none"
                          style={{ boxShadow: "none" }}
                          data-bs-toggle="modal"
                          data-bs-target="#bot-info-modal"
                        >
                          <i class="fas fa-info-circle"></i>
                        </button>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className="text-center mt-2">
                        <button
                          type="buttom"
                          className={`btn btn-xs btn-${
                            profile.data.processing
                              ? "primary"
                              : profile.data.bot
                              ? "danger"
                              : "success"
                          }`}
                          aria-expanded="false"
                        >
                          {profile.data.processing
                            ? "Processing"
                            : profile.data.bot
                            ? "Flagged"
                            : "Unflagged"}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {profile.data && profile.data.processing ? (
        <div
          id="processing-info-modal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-body p-4">
                <div class="text-center">
                  <i class="bx bxs-info-circle h1 text-info"></i>
                  <h4 class="mt-2">Processing info!</h4>
                  <p class="mt-3">Action: {profile.data.bot.action}</p>
                  <p class="mt-3" style={{ wordWrap: "break-word" }}>
                    Reason: {profile.data.bot.reason}
                  </p>
                  <p class="mt-3">
                    Created At:{" "}
                    {moment(profile.data.bot.createdAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </p>
                  <button
                    type="button"
                    class="btn btn-info my-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {profile.data && profile.data.bot ? (
        <div
          id="bot-info-modal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-body p-4">
                <div class="text-center">
                  <i class="bx bxs-info-circle h1 text-info"></i>
                  <h4 class="mt-2">Bot info!</h4>
                  <p class="mt-3" style={{ wordWrap: "break-word" }}>
                    Reason: {profile.data.bot.reason}
                  </p>
                  <p class="mt-3">
                    Updated At:{" "}
                    {moment(profile.data.bot.updatedAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </p>
                  <button
                    type="button"
                    class="btn btn-info my-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div
        id="block-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="block-modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="block-modalLabel">
                Block account
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h6>Are you sure to block this account?</h6>
              <form className="px-3" action="#">
                <div className="mb-3">
                  <label for="message" className="form-label">
                    Reason
                  </label>
                  <textarea
                    className="form-control"
                    name="reason"
                    id="reason"
                    onChange={(e) => setReason(e.target.value)}
                    rows="5"
                    required
                  >
                    {reason}
                  </textarea>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={blockAccount}
              >
                Block
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="unblock-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="unblock-modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="unblock-modalLabel">
                Unblock account
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h6>Are you sure to unblock this account?</h6>
              <form className="px-3" action="#">
                <div className="mb-3">
                  <label for="message" className="form-label">
                    Reason
                  </label>
                  <textarea
                    className="form-control"
                    name="reason"
                    id="reason"
                    onChange={(e) => setReason(e.target.value)}
                    rows="5"
                  >
                    {reason}
                  </textarea>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={unblockAccount}
              >
                Unblock
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cancel-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="cancel-modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="cancel-modalLabel">
                Cancel process
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h6>Are you sure to cancel this process?</h6>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={cancelProcess}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
