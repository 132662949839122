import { Link } from "react-router-dom";
import moment from "moment-timezone";

export const AdminTemplatesGridCard = ({ template, sort, badge = null }) => {
  return (
    <div className="card product-box ribbon-box">
      <div className="product-img" style={{ overflow: "inherit" }}>
        {badge ? (
          <div
            class="ribbon ribbon-primary float-end"
            style={{ marginRight: "-5px", marginBottom: "0px" }}
          >
            {badge}
          </div>
        ) : null}

        <div className="p-3">
          <Link to={`/admin/template/${template.template_id}`}>
            <img
              src={`https://cloudflare-ipfs.com/ipfs/${
                template.immutable_data && template.immutable_data.img
                  ? template.immutable_data.img
                  : "QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP"
              }`}
              alt="nfts-pic"
              className="img-fluid  mx-auto d-block"
              style={{ height: "300px" }}
            />
          </Link>
        </div>
      </div>

      <div className="product-info border-top p-3">
        <div>
          <h5 className="font-16 mt-0 mb-0">
            <Link to={`/admin/template/${template.template_id}`} className="text-dark">
              {template.immutable_data ? template.immutable_data.name : "N/A"}
            </Link>
          </h5>
          <p className="card-text m-0">
            {sort === "created_at_time" ? (
              <small
                className="text-muted"
                data-timestamp={template.created_at_time}
                title={moment(parseInt(template.created_at_time)).fromNow()}
              >
                Created:&nbsp;
                {moment
                  .tz(parseInt(template.created_at_time), "UTC")
                  .format("YYYY-MM-DD HH:mm:ss")}
              </small>
            ) : null}   
          </p>          
        </div>
      </div>
    </div>
  );
};

export default AdminTemplatesGridCard;
