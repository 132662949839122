import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import AccountService from "../../../services/account";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import CardMenu from "admin/components/card-menu";
import usePrevious from "app/hooks/usePrevious";
import Error500 from "admin/components/errors/error-500";
import IpfsImage from "admin/components/ipfs-image";
import config from "app/config";
import { useFilters, useHelpTexts } from "app/hooks/useSession";
import { copyToClipboard } from "app/utils/datatables";
import { ContactsOutlined } from "@material-ui/icons";
import { useState } from "react";
import uniqid from "uniqid";
import crypto from 'crypto';
import Emitter from "app/services/emitter";
import ReportsService from "admin/services/reports";

export const AssetsTransfers = (props) => {
  const componentProps = {
    component: "views/account-scanner/components/assets-transfers",
    options: { props: { account: props.account } },
    grid_options: { x: 0, y: 0, w: 12, h: 10 },
    copy_option: { id: "nfts_transfers" },
  };
  const helpTexts = useHelpTexts();
  const [jobId, setJobId] = useState(crypto.createHash("sha256").update(componentProps.component).digest("hex"));//crypto.createHash("sha256").update(componentProps.component).digest("hex")
  const account = props.account || null;
  const filters = useFilters();
  const tableRef = useRef();
  const dataTableRef = useRef();
  const prevAccount = usePrevious(props.account);
  const apiHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var headers = {};
    if (user) {
      headers["x-access-token"] = user.access_token;
    }

    return headers;
  };
  // const assetsTransfers = useQuery(`assets_transfers_${account}`, async () => {
  //   const response = await AccountService.getAssetsTransfers(account);
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // });

  if (dataTableRef.current && prevAccount !== props.account) {
    dataTableRef.current.destroy();
    dataTableRef.current = null;
  }

  useEffect(() => {
    // if (!assetsTransfers.data) {
    //   return;
    // }
    setTimeout(() => {
      dataTableRef.current =
        dataTableRef.current ||
        window
          .$(tableRef.current)
          .on("preXhr.dt", function (e, settings, data) {
            window.$(tableRef.current).parent().addClass("loading");
          })
          .on("xhr.dt", function (e, settings, data) {
            window.$(tableRef.current).parent().removeClass("loading");
          })
          .DataTable({
            language: {
              paginate: {
                previous: "<i class='mdi mdi-chevron-left'>",
                next: "<i class='mdi mdi-chevron-right'>",
              },
              emptyTable: "No results found within the selected time frame",
              processing: "Processing (This usually takes some time).",
            },
            lengthMenu: [10, 50, 100, 500, 1000],
            searching: false,
            processing: true,
            serverSide: true,
            //ordering: false,
            ajax: {
              url: `${config.api.url}/api/${config.api.version}/account/atomicassets/transfers/${props.account}`,
              cache: true,
              headers: {
                ...apiHeaders(),
              },
              data: function (data) {
                data.startDate = filters.dates.startDate;
                data.endDate = filters.dates.endDate;
                data.jobId = jobId;
              },
              error: function (response, error, code) {
                if (response.status === 202) {
                  setTimeout(function () {
                    dataTableRef.current.ajax.reload(null, false);
                  }, 30000);
                }
              },
            },
            columns: [
              { data: "asset_id", name: "asset_id" },
              { data: "created_at_time", name: "created_at_time" },
              { data: "sender", name: "sender" },
              { data: "recipient", name: "recipient" },
              { data: "memo", name: "memo" },
              { data: "link_creator", name: "link_creator" },
              { data: "backed_wax", name: "backed_wax" },
            ],
            columnDefs: [
              // { targets: 'no-sort', orderable: false },
              {
                targets: 0,
                orderable: false,
                createdCell: function (
                  cell,
                  cellData,
                  rowData,
                  rowIndex,
                  colIndex
                ) {
                  window.$(cell).empty();

                  // var data = `<a href="/admin/nfts/${
                  //   rowData.asset_id
                  // }" target="_blank"><img alt="nfts-pic" src="https://cloudflare-ipfs.com/ipfs/${
                  //   rowData.asset ? rowData.asset.data.img : ""
                  // }" style="width: 50px;"><p>${
                  //   rowData.asset ? rowData.asset.name : "N/A"
                  // }</p></a>`;

                  var data = `<a href="/admin/nfts/${
                    rowData.asset_id
                  }" target="_blank"><p>${
                    rowData.asset ? rowData.asset.name : "N/A"
                  }</p></a>`;

                  window.$(cell).addClass("text-center");
                  window.$(cell).prepend(data);
                },
              },
              {
                targets: 1,
                orderable: false,
                createdCell: function (
                  cell,
                  cellData,
                  rowData,
                  rowIndex,
                  colIndex
                ) {
                  window.$(cell).empty();

                  var data = `${moment
                    .tz(parseInt(rowData.created_at_time), "UTC")
                    .format("YYYY-MM-DD HH:mm:ss")}`;

                  window.$(cell).prepend(data);
                  window
                    .$(cell)
                    .attr("title", moment(rowData.created_at_time).fromNow());
                },
              },
              {
                targets: 2,
                orderable: false,
              },
              {
                targets: 3,
                orderable: false,
              },
            ],
            dom: "Blfrtip",
            buttons: [
              "copy",
              { extend: "csv", title: `NFTs Transfers for ${props.account}` },
              {
                extend: "copy",
                title: null,
                header: false,
                exportOptions: {
                  columns: ':contains("From")',
                  rows: ":visible",
                  customizeData: function (dataTable) {
                    dataTable.body = [
                      ...new Map(
                        dataTable.body.map((item) => [item[0], item])
                      ).values(),
                    ];
                  },
                },
              },
              {
                extend: "copy",
                title: null,
                header: false,
                exportOptions: {
                  columns: ':contains("To")',
                  rows: ":visible",
                  customizeData: function (dataTable) {
                    dataTable.body = [
                      ...new Map(
                        dataTable.body.map((item) => [item[0], item])
                      ).values(),
                    ];
                  },
                },
              },
              {
                extend: "copy",
                title: null,
                header: false,
                exportOptions: {
                  columns: ':contains("LCreator")',
                  rows: ":visible",
                  customizeData: function (dataTable) {
                    dataTable.body = [
                      ...new Map(
                        dataTable.body.map((item) => [item[0], item])
                      ).values(),
                    ];
                  },
                },
              },
            ],
          });
      window.$(".buttons-copy").hide();
      window.$(".buttons-csv").hide();
    }, 500);

    if (dataTableRef.current) {
      dataTableRef.current.draw(true);
    }

    // setTimeout(() => {
    //   window.$.Components.init();
    // });
  }, [props.account]);

  const copyFromAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#nftsTransfers_wrapper .btn-group .buttons-copy`)[1].click();
    copyToClipboard("nfts_transfers", 1);
  };

  const copyToAddressToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#nftsTransfers_wrapper .btn-group .buttons-copy`)[2].click();
    copyToClipboard("nfts_transfers", 2);
  };

  const copyLcreatorToClipboard = async (e) => {
    e.preventDefault();

    //window.$(`#nftsTransfers_wrapper .btn-group .buttons-copy`)[3].click();
    copyToClipboard("nfts_transfers", 3);
  };

  const exportCSV = async () => {
    try {
      const response = await ReportsService.requestReport({
        type: "reportExportNftsTransfersDeduped",
        format: "csv",
        filters: {
          account: props.account,
          start_date: filters.dates.startDate,
          end_date: filters.dates.endDate,
        },
      });
      if (!response) {
        throw Error("Unable to process");
      }

      Emitter.emit("REQUEST_REPORT_SUCCESS");

      window.$.toast({
        heading: "Success",
        text: "Request created Successfully. <a href='/admin/reports/reports'><button class='btn btn-xs btn-primary'>View reports</button></a>",
        position: "top-right",
        icon: "success",
      });
    } catch (err) {
      window.$.toast({
        heading: "Error",
        text: "Export failed: " + err.message,
        position: "top-right",
        icon: "error",
      });
    }
  };

  return (
    <div className="row">
      <div class="col-lg-12">
        <div className="card">
          <div className="card-body">
            <CardMenu {...componentProps} {...props} />
            <div className="float-end me-2">
              <form className="d-flex align-items-center flex-wrap">
                <button
                  type="button"
                  className="btn btn-sm btn-success mb-2 mb-sm-0"
                  onClick={exportCSV}
                >
                  Export as deduped csv
                </button>
              </form>
            </div>
            <h4
              className="header-title"
              data-ht-title={
                helpTexts.find(
                  (helpText) => helpText.key === componentProps.component
                )?.title || `NFTs Transfers`
              }
            >
              {helpTexts.find(
                (helpText) => helpText.key === componentProps.component
              )?.title || `NFTs Transfers`}{" "}
              for {props.account}
              <button
                type="button"
                className="btn btn-outline-none"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-ht-key={componentProps.component}
                title={
                  helpTexts.find(
                    (helpText) => helpText.key === componentProps.component
                  )?.value ||
                  "This shows the most recent NFT transfers to and from this account."
                }
                style={{ boxShadow: "none" }}
              >
                <i class="fas fa-info-circle"></i>
              </button>
            </h4>

            <table
              className="table dt-responsive nowrap w-100"
              ref={tableRef}
              id="nfts_transfers"
            >
              <thead>
                <tr>
                  <th className="text-center">Asset</th>
                  <th>Date</th>
                  <th className="no-sort">
                    From &nbsp;
                    <Link to="" onClick={copyFromAddressToClipboard}>
                      <i
                        className="fas fa-copy"
                        title="Copy from address to clipboard"
                      ></i>
                    </Link>
                  </th>
                  <th className="no-sort">
                    To &nbsp;
                    <Link to="" onClick={copyToAddressToClipboard}>
                      <i
                        className="fas fa-copy"
                        title="Copy to address to clipboard"
                      ></i>
                    </Link>
                  </th>
                  <th>Memo</th>
                  <th className="no-sort">
                    LCreator &nbsp;
                    <Link to="" onClick={copyLcreatorToClipboard}>
                      <i
                        className="fas fa-copy"
                        title="Copy LCreator to clipboard"
                      ></i>
                    </Link>
                  </th>
                  <th>Backed WAX</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetsTransfers;
