import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import TemplatesService from "../../services/templates";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Skeleton from "react-loading-skeleton";
import AdminNftsGrid from "../../components/nfts/nfts-grid";
import AdminNftsTemplateOwnersGrid from "../../components/nfts/nfts-template-owners-grid";

export const AdminTemplateDetail = () => {
  let { templateId } = useParams();

  const template = useQuery(
    [`template_details_${templateId}`, templateId],
    () => {
      return TemplatesService.getTemplate(templateId);
    }
  );

  const templatePrice = useQuery(
    [`template_details_${templateId}_price`, templateId],
    () => {
      return TemplatesService.getTemplatePrice(templateId);
    }
  );

  console.log(templatePrice);

  return (
    <>
      <BreadcrumbsItem to={`/admin/templates/${templateId}`}>
        Template Detail
      </BreadcrumbsItem>

      {template.status !== "loading" &&
      (!template.data || !template.data.template_id) ? (
        <div className="row justify-content-center my-5">
          <div className="col-lg-6 col-xl-4 mb-4">
            <div className="text-center">
              <img
                src="/assets/images/error.svg"
                alt="errorimage"
                width="200"
              />
            </div>
            <div className="text-center">
              <h3 className="mt-2 mb-2">Template not found </h3>
            </div>
          </div>
        </div>
      ) : null}

      {template.status === "loading" ||
      (template.data && template.data.template_id) ? (
        <>
          <div className="row">
            <div class="col-lg-12">
              <h3>
                Template: #
                {template.status === "loading" ? (
                  <Skeleton width={150} />
                ) : (
                  <>
                    {template.data.template_id} ({template.data.name})
                  </>
                )}
              </h3>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4">
              <div className="card  h-100">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div
                        id="template-carousel"
                        className="carousel slide product-detail-carousel"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="">
                              {template.status === "loading" ? (
                                <Skeleton height={300} />
                              ) : (
                                <img
                                  src={`https://cloudflare-ipfs.com/ipfs/${template.data.immutable_data.img}`}
                                  alt="asset-img"
                                  className="img-fluid mx-auto d-block"
                                  style={{ height: "300px" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <ol className="carousel-indicators product-carousel-indicators mt-2">
                          <li
                            data-bs-target="#template-carousel"
                            data-bs-slide-to="0"
                            className="active"
                            style={{ width: "min-content" }}
                          >
                            {template.status === "loading" ? (
                              <Skeleton height={100} />
                            ) : (
                              <img
                                src={`https://cloudflare-ipfs.com/ipfs/${template.data.immutable_data.img}`}
                                alt="asset-img"
                                className="img-fluid product-nav-img"
                              />
                            )}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div class="card  h-100">
                <div className="card-body">
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Template Name</p>
                        {template.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">{template.data.name}</h5>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Collection Name</p>
                        {template.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">{template.data.collection_name}</h5>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">ID</p>
                        {template.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">#{template.data.template_id}</h5>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Schema Name</p>
                        {template.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">{template.data.schema_name}</h5>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Issued supply</p>
                        {template.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">{template.data.issued_supply}</h5>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Max supply</p>
                        {template.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <h5 class="mt-0">{template.data.max_supply}</h5>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Properties</p>
                        {template.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <>
                            {template.data.transferable ? (
                              <h5 class="mt-0">Transferable</h5>
                            ) : null}
                            {template.data.burnable ? (
                              <h5 class="mt-0">Burnable</h5>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="flex-1">
                        <p class="mb-1">Suggested Price </p>
                        {templatePrice.status === "loading" ? (
                          <Skeleton />
                        ) : (
                          <>
                            <h5 class="mt-0">
                              {templatePrice.data ? templatePrice.data.suggested_price_value : "N/A"}{" "}
                              {templatePrice.data ? templatePrice.data.suggested_price_base : "N/A"}($
                              {templatePrice.data ? templatePrice.data.suggested_price_usd_value : "N/A"}){" "}
                            </h5>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div class="col-lg-6">
              <div class="card h-100">
                <div className="card-body">
                  <h4 class="header-title mb-4">Immutable Attributes</h4>
                  <table>
                    {template.isLoading ? (
                      <Skeleton />
                    ) : (
                      <>
                        <tbody>
                          {Object.keys(template.data.immutable_data || {}).map(
                            (attribute, index) => (
                              <tr key={index}>
                                <td>{attribute}</td>
                                <td>
                                  {template.data.immutable_data[attribute]}
                                </td>
                              </tr>
                            )
                          )}
                          {Object.keys(template.data.immutable_data || {})
                            .length === 0 ? (
                            <p>No immutable attributes</p>
                          ) : null}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div class="col-lg-12">
              {template.isLoading ? (
                <Skeleton />
              ) : (
                <AdminNftsGrid
                  templateId={template.data.template_id}
                  scrollLoad={false}
                  hideNfts={false}
                />
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div class="col-lg-12">
              {template.isLoading ? (
                <Skeleton />
              ) : (
                <AdminNftsTemplateOwnersGrid
                  templateId={template.data.template_id}
                  scrollLoad={false}
                />
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AdminTemplateDetail;
